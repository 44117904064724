import loadable from "@loadable/component";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../components/Reusable/Spinner";
import APIS from "../../constant/EndPoint";
import useGetHook from "../../customHooks/useGetHook";
import { patchApiFormData } from "../../helpers/AxiosInstance";
import { useState } from "react";
import usePostHook from "../../customHooks/usePostHook";
const EditServiceComponent = loadable(
  () => import("../../components/Services/EditService")
);
const EditService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [defaultValue, setDefaultValue] = useState<any>([]);
  const handleUpdateFrontendService = (value: any) => {
    setDefaultValue(value);
  };

  const { data: serviceData, isLoading } = useGetHook({
    queryKey: ["services", `${id}`],
    url: `${APIS.SERVICE}${id}/`,
    params: {},
  });
  const handleOpenEditPopup = () => {
    setOpenEditPopup(true);
  };

  const {
    data: AttachedFrontendServiceData,
    isLoading: isAssignServicesLoading,
  } = useGetHook({
    queryKey: [`frontendservice-${id}`],
    url: `${APIS.ASSIGN_FRONTEND_SERVICE}`,
    params: {
      service: id,
    },
  });

  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["frontend-service"],
  });
  const handleAttachServices = async (values: any, setError: any) => {
    const url = `${APIS.ASSIGN_FRONTEND_SERVICE}`;
    let formData = {
      frontend_services: values?.services,
      service: id,
    };

    try {
      await mutateAsync({ url, formData, setError });
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = async (values: any, setError: any) => {
    const formData = new FormData();
    if (values?.logo?.[0]) {
      formData.append("logo", values?.logo?.[0]);
    }
    formData.append("name", values?.name);
    formData.append("url", values?.url);
    formData.append("description", values?.description);
    formData.append("database_name", values?.database_name);
    formData.append("display_name", values?.display_name);
    try {
      let url = `${APIS.SERVICE}${id}/`;
      const res = await patchApiFormData({ url, formData, setError });
      if (res.status === 200) {
        toast.success("Updated Successfully");
        navigate("/services");
      }
    } catch (e) {
      console.log(e);
      toast.success("Somethings went wrongs");
    }
  };

  return (
    <div>
      {isLoading || isAssignServicesLoading ? (
        <Spinner />
      ) : (
        <EditServiceComponent
          serviceData={serviceData}
          handleEdit={handleEdit}
          handleUpdateFrontendService={handleUpdateFrontendService}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          handleOpenEditPopup={handleOpenEditPopup}
          openEditPopup={openEditPopup}
          AttachedFrontendServiceData={AttachedFrontendServiceData}
          isPostLoading={isPostLoading}
          setOpenEditPopup={setOpenEditPopup}
          handleAttachServices={handleAttachServices}
          id={id}
        />
      )}
    </div>
  );
};

export default EditService;

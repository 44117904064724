import React from "react";
import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
const SidebarComponent = loadable(
  () => import("../../components/Client/ClientLayout/Sidebar")
);
const Sidebar = () => {
  const { id } = useParams();
  return (
    <>
      <SidebarComponent id={id} />
    </>
  );
};

export default Sidebar;

import React from "react";
import OverViewIcon from "../Icons/OverViewIcon";
import { NavLink, useLocation, useParams, Outlet } from "react-router-dom";
import useGetHook from "../customHooks/useGetHook";
import APIS from "../constant/EndPoint";

export const navigation: any = [
  {
    name: "Client Update",
    href: "/",
    icon: "",
    children: [
      {
        name: "Basic Setting",
        href: `/clients/submenu/edit/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Domain",
        href: `/clients/submenu/domain/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Services",
        href: `/clients/submenu/client-services/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Color Theme",
        href: `/clients/submenu/color-theme/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "SMS Setting",
        href: `/clients/submenu/sms-setting/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Email Setting",
        href: `/clients/submenu/email-setting/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Subcription",
        href: `/clients/submenu/subcription/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Sidebar",
        href: `/clients/submenu/sidebar/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Theme",
        href: `/clients/submenu/theme/`,
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
    ],
  },
];
const ClinetLayout = () => {
  const location = useLocation();
  const { id } = useParams();
  const { data: clientData } = useGetHook({
    queryKey: [`client-${id}`],
    url: `${APIS.CLIENT}${id}/`,
    params: {},
  });
  return (
    <div className="flex flex-row h-full">
      <div className={`h-full p-1 w-[15rem]  hover:overflow-auto`}>
        <div
          className={`rounded-md h-full overflow-auto px-2`}
          style={{
            background:
              "linear-gradient(135deg, #95ffff, rgba(246, 172, 208, 0.03) 82.72%, rgba(248, 206, 226, 0) 100%)",
          }}
        >
          <div className="font-semibold text-lg text-gray-700 mt-2 ">
            {clientData?.name}
          </div>
          <ul>
            {navigation?.map((item: any, index: number) => (
              <li key={index}>
                <span className={` block font-[18px]  text-gray-500`}>
                  {item.name}
                </span>
                {item.children.length && (
                  <>
                    <ul className={`ml-2`}>
                      {item.children.map((subMenu: any, subIndex: number) => (
                        <React.Fragment key={`subIndex-${subIndex}`}>
                          <NavLink
                            key={subIndex}
                            to={`${subMenu.href}${id}`}
                            className={({ isActive, isPending }) =>
                              `${
                                isActive
                                  ? `bg-[#2fffff] rounded text-primary`
                                  : isPending
                                  ? `text-indigo-100 hover:bg-indigo-600`
                                  : ""
                              } text-gray-700 my-1 cursor-pointer px-2 py-[4px] flex `
                            }
                          >
                            <subMenu.icon
                              color={
                                location.pathname === subMenu.href
                                  ? "var(--primary)"
                                  : "gray"
                              }
                            />
                            <span className={`ml-4`}>{subMenu.name}</span>
                          </NavLink>
                        </React.Fragment>
                      ))}
                    </ul>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={`h-full overflow-auto p-2 w-full`}>
        <Outlet />
      </div>
    </div>
  );
};

export default ClinetLayout;

import loadable from "@loadable/component";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../components/Reusable/Spinner";
import APIS from "../../../constant/EndPoint";
import useGetHook from "../../../customHooks/useGetHook";
import { patchApiFormData } from "../../../helpers/AxiosInstance";
import CreateIcon from "../../../Icons/CreateIcon";

const EditThemeComponent = loadable(
  () => import("../../../components/Client/Theme/EditTheme")
);
const EditTheme = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isPatchLoading, setIsPatchloading] = useState(false);
  const { themeId } = location.state || {};
  const { data, isLoading } = useGetHook({
    queryKey: ["client-theme", `theme-${themeId}`],
    url: `${APIS.THEME}${themeId}/`,
    params: {},
  });
  const handleEdit = async (values: any, setError: any) => {
    setIsPatchloading(true);
    const url = `${APIS.THEME}${themeId}/`;
    const formData = new FormData();
    if (values?.preview_image?.[0]) {
      formData.append("preview_image", values?.preview_image?.[0]);
    }
    if (values?.zip_file?.[0]) {
      formData.append("zip_file", values?.zip_file?.[0]);
    }
    formData.append("name", values?.name);
    try {
      const res = await patchApiFormData({ url, formData, setError });
      if (res?.status === 200) {
        toast.success("Updated Successfully");
        navigate(`/clients/submenu/theme/${id}`);
      } else {
        toast.error(res?.data?.message ?? "Something went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsPatchloading(false);
    }
  };

  return (
    <>
      <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <CreateIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>Update Theme</span>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <EditThemeComponent
          data={data}
          handleEdit={handleEdit}
          isLoading={isPatchLoading}
        />
      )}
    </>
  );
};

export default EditTheme;

const APIS = {
  USER: "/user/",
  TOKEN: "/token/",
  STATUS: "/services-status/",
  SUPPORT: "/ticket/",
  SERVICE: "/services/",
  SUBSCRIPTION: "/client-subscription/",
  ASSIGN_FRONTEND_SERVICE: "/add-frontend-service/",
  ASSIGN_SERVICE: "/assign-services/list-types/",
  CLIENT_SERVICE: "/assign-services/",
  FORGET_PASSWORD: "/user/forget-password/",
  PASSWORD_RESET_SEND_OTP: "/user/send-otp/",
  USER_TYPE: "/add-frontend-service/user-types/",
  FRONTENT_SERVICE: "/frontend-service/",
  SERVICE_TYPE: "/frontend-service/service-type/",
  USER_PROFILE: "/user-profile/",
  CACHED: "/reset-cache/",
  CLIENT: "/client/",
  DOMAIN: "/domain/",
  ROLE: "/role/",
  THEME: "/theme/",
};

export default APIS;

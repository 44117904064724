import loadable from "@loadable/component";
import { useState } from "react";
import CreateIcon from "../../../Icons/CreateIcon";
import APIS from "../../../constant/EndPoint";
import { postApiFormData } from "../../../helpers/AxiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CreateThemeComponent = loadable(
  () => import("../../../components/Client/Theme/CreateTheme")
);
const CreateTheme = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const handleCreate = async (values: any, setError: any) => {
    setIsloading(true);
    const url = APIS.THEME;
    const formData = new FormData();
    if (values?.preview_image?.[0]) {
      formData.append("preview_image", values?.preview_image?.[0]);
    }
    if (values?.zip_file?.[0]) {
      formData.append("zip_file", values?.zip_file?.[0]);
    }
    formData.append("name", values?.name);
    try {
      const res = await postApiFormData({ url, formData, setError });
      if (res?.status === 201) {
        toast.success("Create Successfully");
        navigate(`/clients/submenu/theme/${id}`);
      } else {
        toast.error(res?.data?.message ?? "Something went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <CreateIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>Add Theme</span>
      </div>
      <CreateThemeComponent
        handleCreate={handleCreate}
        isPostLoading={isLoading}
      />
    </>
  );
};

export default CreateTheme;

import React from "react";
import loadable from "@loadable/component";
import APIS from "../../constant/EndPoint";
import { useParams } from "react-router-dom";
import usePatchHook from "../../customHooks/usePatchHook";
import { toast } from "react-toastify";
import useGetHook from "../../customHooks/useGetHook";
import Spinner from "../../components/Reusable/Spinner";
const ClinetThemeComponent = loadable(
  () => import("../../components/Client/ClientTheme/index")
);
const ClinetTheme = () => {
  const { id } = useParams();
  const { data: clientData, isLoading: isClientDataLoading } = useGetHook({
    queryKey: [`client-${id}`],
    url: `${APIS.CLIENT}${id}/`,
    params: {},
  });
  const { mutateAsync } = usePatchHook({
    queryKey: ["client"],
    navigateURL: "",
  });
  const handleColorupload = async (values: any) => {
    let formData = {
      color_palettes: values,
    };
    try {
      let url = `${APIS.CLIENT}${id}/`;
      const res = await mutateAsync({ url, formData });
    } catch (e) {
      console.log(e);
      toast.success("Somethings went wrongs");
    }
  };
  return (
    <>
      {isClientDataLoading ? (
        <Spinner />
      ) : (
        <div>
          <ClinetThemeComponent
            handleColorupload={handleColorupload}
            clientData={clientData}
          />
        </div>
      )}
    </>
  );
};

export default ClinetTheme;

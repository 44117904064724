import { Route, Routes } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import ClientLayout from "../layouts/ClientLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import ConfirmPassordReset from "../pages/Auth/ConfirmPassordReset";
import LoginPage from "../pages/Auth/LoginPage";
import PasswordReset from "../pages/Auth/PasswordReset";
import ClientDomain from "../pages/Client/ClientDomain";
import ClientList from "../pages/Client/ClientList";
import ClientServices from "../pages/Client/ClientServices";

import CreateClient from "../pages/Client/CreateClient";
import EditClient from "../pages/Client/EditClient";
import DomainList from "../pages/Domain/DomainList";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import OverView from "../pages/OverView";
import CreateService from "../pages/Services/CreateService";
import EditService from "../pages/Services/EditService";
import Servicelist from "../pages/Services/Servicelist";
import UserList from "../pages/User/UserList";
import PrivateRoute from "./PrivateRoute";
import CreateSubDomain from "../pages/Client/CreateSubDomain";
import AddClientServices from "../pages/Client/AddClientServices";
import ClinetTheme from "../pages/Client/ClinetTheme";
import EmailSetting from "../pages/EmailSetting/EmailSetting";
import SMSsetting from "../pages/SMSsetting/SMSsetting";
import FrontentServiceslist from "../pages/FrontentSevices/FrontentServiceslist";
import FrontendServiceCreate from "../pages/FrontentSevices/FrontendServiceCreate";
import EditFornentService from "../pages/FrontentSevices/EditFornentService";
import EditDomain from "../pages/Domain/EditDomain";
import EditClientDomain from "../pages/Client/EditClientDomain";
import TicketList from "../pages/Ticket/TicketList";
import ClientSubcription from "../pages/Client/ClientSubcription";
// import AddSidebar from "../pages/Client/AddSidebar";
import Sidebar from "../pages/Client/Sidebar";
import AddSidebar from "../pages/Client/AddSidebar";
import ThemeList from "../pages/Client/Theme/ThemeList";
import CreateTheme from "../pages/Client/Theme/CreateTheme";
import EditTheme from "../pages/Client/Theme/EditTheme";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={"/login"} element={<LoginPage />} />
        <Route path={"/forget-password"} element={<PasswordReset />} />
        <Route
          path={"/confirm-password-reset"}
          element={<ConfirmPassordReset />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path="/" element={<OverView />} />
        <Route path="/clients" element={<ClientList />} />
        <Route path="/clients/create" element={<CreateClient />} />
        <Route
          element={
            <PrivateRoute>
              <ClientLayout />
            </PrivateRoute>
          }
        >
          <Route
            path="/clients/submenu/domain/:id"
            element={<ClientDomain />}
          />
          <Route
            path="/clients/submenu/domain/:id/create"
            element={<CreateSubDomain />}
          />
          <Route
            path="/clients/submenu/domain/:id/edit/"
            element={<EditClientDomain />}
          />
          <Route
            path="/clients/submenu/color-theme/:id"
            element={<ClinetTheme />}
          />
          <Route
            path="/clients/submenu/client-services/:id"
            element={<ClientServices />}
          />
          <Route
            path="/clients/submenu/client-services/:id/create"
            element={<AddClientServices />}
          />
          <Route path="/clients/submenu/sidebar/:id" element={<Sidebar />} />
          <Route
            path="/clients/submenu/email-setting/:id"
            element={<EmailSetting />}
          />
          <Route
            path="/clients/submenu/sms-setting/:id"
            element={<SMSsetting />}
          />
          <Route
            path="/clients/submenu/subcription/:id"
            element={<ClientSubcription />}
          />
          <Route
            path="/clients/submenu/sidebar/:id/edit"
            element={<AddSidebar />}
          />
          <Route path="/clients/submenu/edit/:id" element={<EditClient />} />
          <Route path="/clients/submenu/theme/:id" element={<ThemeList />} />
          <Route
            path="/clients/submenu/theme/:id/create"
            element={<CreateTheme />}
          />
          <Route
            path="/clients/submenu/theme/:id/edit"
            element={<EditTheme />}
          />
        </Route>
        <Route path="/domains" element={<DomainList />} />
        <Route path="/domains/edit/:id" element={<EditDomain />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/services" element={<Servicelist />} />
        <Route path="/services/create" element={<CreateService />} />
        <Route path="/services/edit/:id" element={<EditService />} />
        <Route path="/frontent-services" element={<FrontentServiceslist />} />
        <Route
          path="/frontent-services/create"
          element={<FrontendServiceCreate />}
        />
        <Route
          path="/frontent-services/edit/:id"
          element={<EditFornentService />}
        />
        <Route path="/ticket" element={<TicketList />} />
        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;

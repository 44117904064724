import React from "react";
import { useLocation, useParams } from "react-router-dom";
import loadable from "@loadable/component";
import CreateIcon from "../../Icons/CreateIcon";
import APIS from "../../constant/EndPoint";
import usePostHook from "../../customHooks/usePostHook";
const SidebarComponent = loadable(
  () => import("../../components/Client/ClientLayout/AddSidebar")
);

const AddSidebar = () => {
  const { id } = useParams();
  const location = useLocation();
  const { serviceId } = location.state || {};
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["side-bar"],
    navigateURL: "",
  });
  const handleAddSidebar = async (values: any, setError: any) => {
    const url = APIS;
    const formData = {
      ...values,
      client: id,
      service: serviceId,
    };
    try {
      await mutateAsync({ url, formData, setError });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <CreateIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>Add SideBar</span>
      </div>
      <SidebarComponent
        handleAddSidebar={handleAddSidebar}
        isLoading={isPostLoading}
      />
    </>
  );
};

export default AddSidebar;

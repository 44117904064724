import React from "react";
import loadable from "@loadable/component";
import APIS from "../../../constant/EndPoint";
import usePostHook from "../../../customHooks/usePostHook";
import { useParams } from "react-router-dom";
import useDeleteHook from "../../../customHooks/useDeleteHook";

const ThemeListComponent = loadable(
  () => import("../../../components/Client/Theme/ThemeList")
);
const ThemeList = () => {
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["clients-theme"],
  });
  const { mutateAsync: deleteasync, isLoading } = useDeleteHook({
    queryKey: ["clients-theme"],
  });
  const { id } = useParams();
  const handleActive = async (value: any) => {
    const url = `${APIS.THEME}${value}/activate/`;
    const formData = {
      client: id,
    };
    try {
      await mutateAsync({ url, formData });
    } catch (err) {
      console.error(err);
    }
  };
  const handleDelete = async (value: any, setError: any) => {
    const url = `${APIS.THEME}${value}/`;
    const formData = {};
    try {
      await deleteasync({ url, formData, setError });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <ThemeListComponent
      handleActive={handleActive}
      isPostLoading={isPostLoading}
      handleDelete={handleDelete}
      isLoading={isLoading}
    />
  );
};

export default ThemeList;

import React from "react";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../constant/EndPoint";
import { postApiFormData } from "../../helpers/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const CreateServiceComponent = loadable(
  () => import("../../components/Services/CreateService")
);
const CreateService = () => {
  const navigate = useNavigate();
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["services"],
  });
  const handleCreate = async (values: any, setError: any) => {
    const formData = new FormData();
    formData.append("logo", values?.logo[0] ?? "");
    formData.append("name", values?.name);
    formData.append("url", values?.url);
    formData.append("display_name", values?.display_name);
    formData.append("database_name", values?.database_name);
    formData.append("description", values?.description);
    try {
      let url = `${APIS.SERVICE}`;
      const res = await postApiFormData({ url, formData });
      if (res.status === 201) {
        toast.success("Created Successfully");
        navigate("/services");
      }
    } catch (e) {
      console.log(e);
      toast.success("Somethings went wrongs");
    }
  };

  return (
    <div>
      <CreateServiceComponent handleCreate={handleCreate} />
    </div>
  );
};

export default CreateService;

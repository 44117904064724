import loadable from "@loadable/component";
import APIS from "../../constant/EndPoint";
import usePatchHook from "../../customHooks/usePatchHook";
const ClientListComponent = loadable(
  () => import("../../components/Client/ClientList")
);

const ClientList = () => {
  const { mutateAsync } = usePatchHook({
    queryKey: ["clients"],
  });
  const handleIsActive = async (value: any, id: string) => {
    const url = `${APIS.CLIENT}${id}/`;
    const formData = {
      is_active: value,
    };
    try {
      await mutateAsync({ url, formData });
    } catch (err) {
      console.error(err);
    }
  };
  return <ClientListComponent handleIsActive={handleIsActive} />;
};

export default ClientList;
